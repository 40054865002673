import { HowToUseContent } from "../brand_l2/how_to_use_content";
import { Spacer } from "~/widgets/spacer";
import { openIntercom } from "~/utils/third_party/intercom";
import { Divider, DividerV2 } from "~/widgets/divider";
import { HubbleFullSvg } from "~/assets/assets";
import { useModal } from "../modal";
import { Tncs } from "../brand_l2/tncs";
import { JSX, Show } from "solid-js";
import { showSnackBar } from "~/shared_states/snackbar";
import { BrandDetailResponse } from "~/server/types/gift";
import { FAQ } from "~/data/static_faqs/types";
import { GiftingFAQs } from "./gift-box/faqs";
import { HubbleEvent, webEventManager } from "~/data/events";
import { getGiftPrefixedIntercomProps } from "./gift-box/gift_intercom_props";

export function GiftFooter(props: {
  brand: BrandDetailResponse;
  isPreview?: boolean;
  showGuidelines?: boolean;
  senderPhoneNumber?: string;
  tncSubtitle?: string | undefined;
  faqs?: FAQ[];
  defaultEventProps?: Record<string, any>;
  buildBranding?: () => JSX.Element;
  buildGuidlines?: () => JSX.Element;
}) {
  const { setIsModalOn, updateModalContentGetter, setThemeMode } = useModal()!;

  return (
    <div
      class="flex w-full items-center justify-center bg-baseDark px-8 sm:px-0"
      id="gift-footer"
    >
      <div class="flex w-full flex-col md:max-w-[320px]">
        <Spacer height={12} />
        <ActionButton
          text="HOW TO REDEEM THIS CARD?"
          shortText="how_to_redeem"
          defaultEventProps={props.defaultEventProps}
          onClick={() => {
            updateModalContentGetter(() => {
              return <HowToUseContent brandId={props.brand.brandId} />;
            });
            setThemeMode("dark");
            setIsModalOn(true);
          }}
        />
        <ActionButton
          text="THINGS TO NOTE"
          shortText="tnc"
          defaultEventProps={props.defaultEventProps}
          onClick={() => {
            updateModalContentGetter(() => {
              return (
                <Tncs
                  tncResId={props.brand.tncResId ?? ""}
                  subtitle={props.tncSubtitle}
                />
              );
            });
            setThemeMode("dark");
            setIsModalOn(true);
          }}
        />
        <ActionButton
          text="FACING ISSUES? CONTACT US"
          shortText="contact_us"
          defaultEventProps={props.defaultEventProps}
          onClick={() => {
            if (props.isPreview) {
              showSnackBar({
                message: "Support section will open",
                level: "success",
              });

              return;
            }
            openIntercom(
              getGiftPrefixedIntercomProps(props.defaultEventProps ?? {})
            );
          }}
        />
        <Show when={props.faqs}>
          <ActionButton
            text="FAQS"
            shortText="faqs"
            defaultEventProps={props.defaultEventProps}
            onClick={() => {
              updateModalContentGetter(() => {
                return <GiftingFAQs faqs={props.faqs!} />;
              });
              setThemeMode("dark");
              setIsModalOn(true);
            }}
          />
        </Show>
        {props.buildGuidlines?.() ?? (
          <Show when={props.showGuidelines}>
            <Spacer height={24} />
            <RedemptionGuideline senderNumber={props.senderPhoneNumber ?? ""} />
          </Show>
        )}
        <Spacer height={24} />
        {props.buildBranding?.() ?? (
          <GiftBranding isPreview={props.isPreview} />
        )}
        <Spacer height={24} />
      </div>
    </div>
  );

  function ActionButton(props: {
    text: string;
    onClick: () => void;
    shortText: string;
    defaultEventProps?: Record<string, any>;
  }) {
    return (
      <div>
        <div
          class="flex cursor-pointer flex-row items-center py-[18px] text-baseSecondaryMedium"
          onClick={() => {
            webEventManager.sendEvent(HubbleEvent.CLICK_GIFT_FOOTER, {
              cta: props.shortText,
              ...props.defaultEventProps,
            });
            props.onClick();
          }}
        >
          <div class=" grow  text-[10px] font-medium tracking-[1.8px]">
            {props.text}
          </div>
          <i class="ph ph-caret-right"></i>
        </div>
        <Divider bgColor="#333232" />
      </div>
    );
  }

  function RedemptionGuideline({ senderNumber }: { senderNumber: string }) {
    const guidelines = [
      `Please use phone no. of your gifter (${senderNumber}) at the time of billing while redeeming this gift card.`,
      "Cashier may ask for OTP received on your gifter’s phone no. for successful redemption.",
    ];

    return (
      <div class="flex flex-col rounded-xl border-[1px] border-[#362E11] bg-[#221C06] p-3 font-[Inter] tracking-[-0.13px]">
        <p class="font-[Inter] text-[10px] font-medium tracking-[1.8px] text-[#E0B827]">
          PLEASE NOTE
        </p>
        <Spacer height={4} />
        <p class="font-[Inter] text-[13px] tracking-[-0.13px] text-[#E0B827]">
          For security reasons, this brand has some<br></br>redemption
          guidelines
        </p>
        <Spacer height={8} />
        <DividerV2 class="bg-[#362E11]" />
        <Spacer height={8} />
        <ul class="flex flex-col gap-2">
          {guidelines.map((guideline, index) => (
            <li class="flex flex-row gap-1 text-[13px] font-normal tracking-[-0.13px] text-baseTertiaryLight ">
              <div>&nbsp&nbsp{`•`}&nbsp&nbsp</div>
              <div>{guideline}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export function GiftBranding(props: { isPreview?: boolean }) {
  return (
    <div
      onClick={() => {
        if (props.isPreview) {
          return;
        }
        window.open("https://offers.myhubble.money", "_blank");
      }}
      class="flex h-full w-full cursor-pointer  flex-row items-center justify-center   py-2 text-[10px] font-medium text-[#FFF1A7]"
    >
      POWERED BY
      <div class="w-2" />
      <HubbleFullSvg class="h-[12px] translate-y-[-2px] " fill="#FFF1A7" />
    </div>
  );
}
